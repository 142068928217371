import * as React from "react";
import { navigate } from "gatsby";

import storage from "../storage";

export default () => {
  React.useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      const slug = params.get("slug");

      if (slug) {
        await storage.setItem("promo", slug, { crossDomain: true });
      }

      navigate("/");
    })();
  }, []);
  return null;
};
